import React, { Component } from 'react'
import Layout from '../components/Layout'
import OurCompanyNavigationRow from '../components/OurCompanyNavigationRow'

export default class TermsOfService extends Component {
    render() {
        return (
            <Layout
                title="Terms of Service | HomeSpotter"
                description="Read about our stated terms of service at homespotter.com"
            >
                <OurCompanyNavigationRow location={this.props.location} />
                <div className="ourcompany terms terms-of-service">
                    <h1>HOMESPOTTER TERMS OF SERVICE</h1>
                    <p>
                        The following Terms of Service (the "
                        <strong>Terms</strong>") govern Customer’s use of the
                        System as agreed on the Order Form. The Terms, together
                        with the Order Form, shall be referred to as the
                        Agreement.
                    </p>

                    <h2>
                        <span className="section-label">1.</span>Access to
                        Software.
                    </h2>
                    <p>
                        <span className="section-label">1.1</span>
                        <span className="section-sub-heading">
                            License Grant.
                        </span>{' '}
                        Subject to the terms and conditions of the Agreement,
                        HomeSpotter grants to Customer a non-exclusive,
                        non-transferable, limited term, license to access and
                        use the proprietary software applications and applicable
                        mobile apps developed, owned and hosted by HomeSpotter,
                        as identified on the Order Form, including but not
                        limited to Connect, Boost, and Spacio (the "
                        <strong>System</strong>") solely for Customer’s internal
                        business purposes. HomeSpotter and its licensors reserve
                        all rights in and to the System not expressly granted to
                        Customer. Customer will provide, at Customer’s location,
                        hardware, software, and communications equipment which
                        will allow Customer to access and use the System.
                        Customer will be responsible for providing all
                        additional equipment and internet connectivity at its
                        own expense.{' '}
                    </p>
                    <p>
                        <span className="section-label">1.2</span>
                        <span className="section-sub-heading">
                            Updates.
                        </span>{' '}
                        From time to time, HomeSpotter may make scheduled and/or
                        unscheduled deployments of updates to the System. During
                        such deployments, all or selected portions of the System
                        may be unavailable. In the event HomeSpotter provides
                        Customer with updates, Customer agrees that any such
                        updates will be governed by these Terms.{' '}
                    </p>

                    <h2>
                        <span className="section-label">2.</span>Restrictions on
                        Use.
                    </h2>
                    <p>
                        Customer may not: (a) copy or otherwise reproduce or
                        permit the copying or other reproduction of all or any
                        part of the System except as otherwise permitted herein;
                        (b) reverse engineer, decompile, disassemble or create
                        derived works based on the System; (c) modify, adapt,
                        translate into other programming forms or languages or
                        extend the System to operate in other environments or on
                        other platforms, except in accordance with these Terms;
                        or (d) allow access to the System by other software
                        products for any purpose without prior approval of
                        HomeSpotter.
                    </p>

                    <h2>
                        <span className="section-label">3.</span>Fees and
                        Payment.
                    </h2>
                    <p>
                        Customer will pay HomeSpotter for the System in
                        accordance with the terms set forth in the Order Form.
                        Rates described on the Order Form are exclusive of
                        taxes, levies, duties, governmental charges or expenses.
                        If Order Form states that Customer is to be invoiced,
                        invoices remaining unpaid for more than thirty (30) days
                        from receipt will accrue interest at a rate of the
                        lesser of one and one-half (1.5%) percent per month or
                        the highest rate allowed by law, whichever is less.
                        Customer will be liable for all costs and expenses
                        related to collection of past due amounts, including
                        legal and other professional fees and expenses of
                        litigation. HomeSpotter’s rights under this section will
                        be in addition to all other rights and remedies
                        available to HomeSpotter upon Customer’s default.
                    </p>

                    <h2>
                        <span className="section-label">4.</span>Proprietary
                        Rights.{' '}
                    </h2>
                    <p>
                        <span className="section-label">4.1</span>
                        <span className="section-sub-heading">
                            Customer Data.
                        </span>{' '}
                        Customer retains ownership of its data that is processed
                        or hosted by the System, including graphics and text
                        provided by Customer for inclusion. Customer hereby
                        grants to HomeSpotter for the Term a non-exclusive,
                        worldwide license to use, reproduce, distribute,
                        display, and modify any user-perceptible text and
                        multimedia information, including sound, data, text,
                        designs, audio, video, graphics, photographs,
                        information, Customer advertisements, and the like
                        provided by Customer to HomeSpotter through Customer’s
                        use of the System ("<strong>Customer Data</strong>").{' '}
                    </p>

                    <p>
                        <span className="section-label">4.2.</span>
                        <span className="section-sub-heading">
                            Software Applications.
                        </span>{' '}
                        All right, title and interest (including all
                        intellectual property rights embodied therein) in and to
                        the System and will remain the sole and exclusive
                        property of HomeSpotter. These Terms grant Customer no
                        title or right of ownership in or to the System, or any
                        component thereof including source code, or to any
                        associated materials, documentation, intellectual
                        property, or in or to any derivates of the System,
                        enhancements, modifications or improvements thereto.
                        Customer will not, at any time, take or cause any
                        action, which would be inconsistent with or tend to
                        impair the rights of HomeSpotter or its affiliates in
                        the System. Customer may not remove or alter any of
                        HomeSpotter’s proprietary or copyright notices,
                        trademarks or logos.
                    </p>

                    <p>
                        <span className="section-label">4.3.</span>
                        <span className="section-sub-heading">
                            Confidentiality.
                        </span>{' '}
                        Customer acknowledge that the existence and the terms of
                        this Agreement and any oral or written information
                        exchanged between the parties in connection with the
                        preparation and performance this Agreement are regarded
                        as confidential information. Customer shall maintain
                        confidentiality of all such confidential information,
                        and without obtaining the written consent of
                        HomeSpotter, it shall not disclose any relevant
                        confidential information to any third parties, except
                        for the information that: (a) is or will be in the
                        public domain (other than through Customer’s
                        unauthorized disclosure); (b) is under the obligation to
                        be disclosed pursuant to the applicable laws or
                        regulations, rules of any stock exchange, or orders of
                        the court or other government authorities; or (c) is
                        required to be disclosed by any party to its
                        shareholders, investors, legal counsels or financial
                        advisors regarding the transaction contemplated
                        hereunder, provided that such shareholders, investors,
                        legal counsels or financial advisors shall be bound by
                        the confidentiality obligations similar to those set
                        forth in this Section. Disclosure of any confidential
                        information by the staff members or agencies hired by
                        Customer shall be deemed disclosure of such confidential
                        information by Customer, and Customer shall be held
                        liable for breach of this Agreement.
                    </p>

                    <h2>
                        <span className="section-label">5.</span>Term and
                        Termination
                    </h2>
                    <p>
                        The Agreement will commence upon the Effective Date as
                        stated on the Order Form and remain effective for the
                        period set forth in the Order Form unless terminated as
                        permitted in this Section (the "
                        <strong>Initial Term</strong>"). Unless otherwise agreed
                        upon in the Order Form, the Initial Term will
                        automatically renew for successive one-year periods
                        (together, the "<strong>Term</strong>"), unless either
                        party gives the other party written notice of
                        non-renewal at least 90 days prior to the end of the
                        then-current Term, or terminates the Agreement pursuant
                        to this Section. Either party may terminate the
                        Agreement for cause if the other party (a) materially
                        breaches the Agreement and fails to cure such breach
                        within 30 calendar days of receiving a written notice of
                        breach from the non-breaching party; (b) ceases to do
                        business in the normal course, (c) becomes or is
                        declared insolvent or bankrupt, (d) is the subject of
                        any proceeding related to its liquidation or insolvency
                        (whether voluntary or involuntary) which is not
                        dismissed within 90 calendar days or (e) makes an
                        assignment for the benefit of creditors. This Section 5
                        contains the parties’ exclusive termination rights,
                        unless otherwise provided in an Order Form. Termination
                        of the Agreement will relieve HomeSpotter of all
                        obligations to provide Customer access to the System and
                        any licenses granted under the Agreement will
                        immediately cease.
                    </p>

                    <h2>
                        <span className="section-label">6.</span>Idemnification
                    </h2>
                    <p>
                        <span className="section-label">6.1.</span>
                        <span className="section-sub-heading">
                            By HomeSpotter.
                        </span>{' '}
                        HomeSpotter will indemnify and defend Customer and its
                        affiliates, directors, officers, employees and agents
                        with respect to any claims, liabilities, damages and
                        expenses, including reasonable attorneys’ fees, arising
                        out of any third-party claim that the System as provided
                        by HomeSpotter infringes on any copyright, patent,
                        trademark, trade secret or other intellectual property
                        right of any third party. Notwithstanding the foregoing,
                        HomeSpotter will have no obligation pursuant to the
                        foregoing indemnification provision to the extent that
                        any claim is based on or related to: (a) any use of the
                        System in violation of the Agreement, (b) any use of the
                        System in conjunction with any third party service,
                        data, hardware or software not provided by HomeSpotter,
                        or (c) any material or data provided by Customer. If
                        Customer's use of the System becomes, or is likely to
                        become, the subject of an infringement claim,
                        HomeSpotter may, at its option and expense (i) procure
                        the right for Customer to continue using the System,
                        (ii) replace or modify the infringing components of the
                        System with non-infringing components of substantially
                        equivalent functionality. The foregoing states the
                        entire liability of HomeSpotter with respect to this
                        Section, and Customer hereby expressly waives any other
                        remedies for infringement claims.
                    </p>
                    <p>
                        <span className="section-label">6.2.</span>
                        <span className="section-sub-heading">
                            By Customer.
                        </span>{' '}
                        Customer will indemnify and defend HomeSpotter and its
                        affiliates, directors, officers, employees and agents
                        with respect to any claims, liabilities, damages and
                        expenses, including reasonable attorneys’ fees, arising
                        out of (a) any material or data provided by Customer, or
                        (b) a breach of any of Customer's representations,
                        warranties, obligations, covenants or agreements under
                        the Agreement.
                    </p>
                    <p>
                        <span className="section-label">6.3.</span>
                        <span className="section-sub-heading">
                            Indemnification Procedures.
                        </span>{' '}
                        A party seeking indemnification hereunder (an "
                        <strong>Indemnified Party</strong>") will give the Party
                        from whom indemnification is sought (the "
                        <strong>Indemnifying Party</strong>"): (a) reasonably
                        prompt notice of the relevant claim; (b) reasonable
                        cooperation and assistance, at the Indemnifying Party’s
                        request and expense, in the defense or settlement of
                        such claim; and (c) sole control the defense and
                        settlement of any such claim; provided, however, that
                        the Indemnifying Party will not, without the prior
                        written approval of the Indemnified Party, settle or
                        dispose of any claims in a manner that affects the
                        Indemnified Party’s rights or interest. The Indemnified
                        Party will have the right to participate in the defense
                        at its own expense.{' '}
                    </p>

                    <h2>
                        <span className="section-label">7.</span>WARRANTY
                        DISCLAIMER.
                    </h2>
                    <p>
                        ALL WARRANTIES, CONDITIONS AND OTHER TERMS IMPLIED BY
                        STATUTE, COMMON LAW OR IN ANY OTHER WAY, INCLUDING ANY
                        IMPLIED WARRANTIES AS TO QUALITY, PERFORMANCE, TITLE,
                        NONINFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A
                        PARTICULAR PURPOSE AND ALL WARRANTIES ARISING OUT OF
                        COURSE OF DEALING, COURSE OF PERFORMANCE AND USAGE OF
                        TRADE, ARE EXCLUDED FROM THE AGREEMENT TO THE FULLEST
                        EXTENT PERMITTED BY LAW. THE SYSTEM IS PROVIDED ON AN
                        “AS IS” BASIS AND CUSTOMER’S USE OF THE SYSTEM IS AT ITS
                        OWN RISK. HOMESPOTTER DOES NOT WARRANT THAT THE SYSTEM
                        WILL MEET CUSTOMER'S REQUIREMENTS OR THAT THE OPERATION
                        OF THE SYSTEM WILL BE UNINTERRUPTED OR COMPLETELY SECURE
                        OR ERROR-FREE. CUSTOMER ASSUMES RESPONSIBILITY FOR
                        TAKING ADEQUATE PRECAUTIONS AGAINST DAMAGES WHICH COULD
                        BE CAUSED BY DEFECTS, INTERRUPTIONS OR MALFUNCTIONS IN
                        THE SYSTEM OR THE HARDWARE ON WHICH IT IS INSTALLED.{' '}
                    </p>

                    <h2>
                        <span className="section-label">8.</span>Limitation of
                        Liability.
                    </h2>
                    <p>
                        HOMESPOTTER WILL NOT BE LIABLE FOR VIOLATION OF ANY
                        APPLICABLE LAW, RULE OR REGULATION OR TO ANY THIRD PARTY
                        FOR CLAIMS ARISING OUT OF OR RELATED TO CUSTOMER DATA
                        PROVIDED TO HOMESPOTTER OR PLACED ON THE SYSTEM BY
                        CUSTOMER OR AT CUSTOMER’S DIRECTION. IN NO EVENT WILL
                        (A) HOMESPOTTER BY LIABLE FOR INDIRECT, INCIDENTAL,
                        SPECIAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES
                        ARISING OUT OF OR IN CONNECTION WITH THE AGREEMENT OR
                        THE SYSTEM; AND (B) HOMESPOTTER’S TOTAL CUMULATIVE
                        LIABILITY TO CUSTOMER EXCEED THE AMOUNT PAID TO
                        HOMESPOTTER UNDER THE AGREEMENT DURING THE PREVIOUS
                        TWELVE (12) MONTH PERIOD.
                    </p>

                    <h2>
                        <span className="section-label">9.</span>Miscellaneous.
                    </h2>
                    <p>
                        <span className="section-label">9.1.</span>
                        <span className="section-sub-heading">
                            Assignment.
                        </span>{' '}
                        Customer may not assign the Agreement or any right
                        created hereunder without the prior written consent of
                        HomeSpotter. Any prohibited assignment is void. The
                        Agreement shall inure to the benefit of the parties’
                        respective permitted successors and assigns
                    </p>
                    <p>
                        <span className="section-label">9.2.</span>
                        <span className="section-sub-heading">
                            Amendments; Waiver; Severability.
                        </span>{' '}
                        The Agreement may only be amended or modified in a
                        writing duly executed by authorized representatives of
                        both parties. Any waiver of any breach of any term or
                        any condition of the Agreement will not be construed as
                        a waiver of any subsequent breach of any term or
                        condition of the Agreement. If any part, term or
                        provision of the Agreement will be held to be illegal or
                        unenforceable it will not affect the validity or
                        enforceability of the remainder of the Agreement. The
                        parties will replace any invalid provision with a valid
                        provision, which most closely approximates the intent
                        and economic effect of the invalid provision.
                    </p>
                    <p>
                        <span className="section-label">9.3.</span>
                        <span className="section-sub-heading">
                            Publicity.
                        </span>{' '}
                        HomeSpotter may disclose in its advertising and
                        marketing materials that: (a) Customer has entered into
                        the Agreement with HomeSpotter; and (b) Customer is a
                        customer of HomeSpotter.
                    </p>
                    <p>
                        <span className="section-label">9.4.</span>
                        <span className="section-sub-heading">
                            Disputes.
                        </span>{' '}
                        The Agreement will be governed and construed in
                        accordance with the laws of the State of Minnesota
                        without giving effect its conflict of law principles.
                        The 1980 U.N. Convention on Contracts for the
                        International Sale of Goods does not apply to the
                        Agreement. All disputes arising from or relating to the
                        Agreement will be within the exclusive jurisdiction of
                        the state and/or federal courts located within Hennepin
                        County, Minnesota. Any claim arising from or related to
                        the Agreement must be brought in the state or federal
                        courts located in Minneapolis, Minnesota.{' '}
                    </p>
                    <p>
                        <span className="section-label">9.5.</span>
                        <span className="section-sub-heading">
                            Entire Agreement.
                        </span>{' '}
                        The Agreement, including the Order Form and any other
                        exhibits, comprises the entire agreement between the
                        parties relating to the subject matter hereof. The
                        Agreement supersedes all prior and contemporaneous
                        agreements, proposals, or representations, written or
                        oral, concerning the subject matter of the Agreement. If
                        there is any conflict between the Order Form and these
                        Terms, the provisions of the Order Form will control.
                        The Agreement may be executed in several counterparts,
                        each of which will be deemed to be an original, and all
                        of which, when taken together, will constitute one and
                        the same instrument. Sections 4 (Proprietary Rights), 7
                        (Warranty Disclaimer), 8 (Limitation of Liability), and
                        9 (Miscellaneous) survive termination of the Agreement.
                    </p>
                </div>
            </Layout>
        )
    }
}
